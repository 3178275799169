import React from "react";
import {SignatureProvider} from "../components/SigningComponent/SigningComponent";

interface ISignatureParametersContext {
    predefinedSignatureOptions: boolean;
    signatureToken: string | undefined | null;
    pdfFile: Uint8Array | undefined;
    pdfFileBase64: string | undefined;
    phoneNumber: string | undefined | null;
    firstName: string | undefined | null;
    lastName: string | undefined | null;
    jurisdiction: string | undefined | null;
    signatureLevel: string | undefined | null;
    prepaid: string | undefined | null;
    targetIdentUrl: string | undefined | null;
    storageId: string | undefined | null;
    signatureProvider: SignatureProvider;
    comingFromThirdParty: boolean | undefined,
    backToDocAfterThirdPartySign: boolean | undefined,
    rejectUrl: string | undefined | null
    isDocumentSigned: boolean
}

const SignatureParametersContext =
    React.createContext<ISignatureParametersContext>({
        predefinedSignatureOptions: false,
        signatureToken: undefined,
        pdfFile: undefined,
        pdfFileBase64: undefined,
        phoneNumber: undefined,
        firstName: undefined,
        lastName: undefined,
        jurisdiction: undefined,
        signatureLevel: undefined,
        prepaid: undefined,
        targetIdentUrl: undefined,
        storageId: undefined,
        signatureProvider: SignatureProvider.SwisscomAis,
        comingFromThirdParty: false,
        backToDocAfterThirdPartySign: false,
        rejectUrl: undefined,
        isDocumentSigned: false
    });

export default SignatureParametersContext;
