import React from "react";
import SettingsButtonMenu from "../SettingsButtonMenu/SettingsButtonMenu";
import styles from "./TopToolbar.module.css";
import ESignLiteLogo from "../../images/PVB_3.png";
import LanguageButton from "../LanguageButton/LanguageButton";

function PdfViewerTopToolbar(props: any) {
    return (
        <div className={styles.Toolbar}>
            <div className={styles.LogoContainer}>
                <a href="/">
                    <img
                        className={styles.ToolbarLogo}
                        src={ESignLiteLogo}
                        alt="Vizibit logo"
                    />
                </a>
            </div>
            <div className={styles.Signator_ToolbarSlotsDiv}>
                <div
                    className={styles.Signator_ToolbarSlotContainer}
                    style={{display: "flex", alignItems: "center", paddingTop: "5px"}}
                >
                    <div style={{padding: "0px 2px"}}>
                        <props.slots.ZoomOut/>
                    </div>
                    {!props.isMobile && (<div style={{padding: "0px 2px"}}>
                            <props.slots.Zoom/>
                        </div>
                    )}
                    <div style={{padding: "0px 2px"}}>
                        <props.slots.ZoomIn/>
                    </div>
                    {!props.isMobile && (
                        <div style={{padding: "0px 2px", marginLeft: "auto"}}>
                            <props.slots.GoToPreviousPage/>
                        </div>)}
                    {!props.isMobile && (<div style={{padding: "0px 2px"}}>
                            <props.slots.GoToNextPage/>
                        </div>
                    )}
                    <div style={{padding: "0px 2px"}}>
                        <props.slots.Download/>
                    </div>
                    {!props.isMobile && (
                        <div style={{padding: "0px 2px"}}>
                            <props.slots.Print/>
                        </div>
                    )}
                </div>
            </div>
            <div style={{display: "flex", width: "20%", justifyContent: "flex-end"}}>
                <div className={styles.ToolbarRightMenu}>
                    <div className={styles.ToolbarMenuLanguage}>
                        <LanguageButton/>
                    </div>
                </div>
                <div className={styles.ToolbarRightMenu}>
                    <div className={styles.ToolbarMenuLanguage}>
                        <SettingsButtonMenu backgroundColor={"#f5f5f5"}/>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PdfViewerTopToolbar;
