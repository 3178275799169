import React, {
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react";
import {
  DocumentLoadEvent,
  LoadError,
  Plugin,
  RenderPage,
  RenderPageProps,
  SpecialZoomLevel,
  Viewer,
  Worker,
  ZoomEvent,
} from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import {
  toolbarPlugin,
  ToolbarProps,
  ToolbarSlot,
} from "@react-pdf-viewer/toolbar";
import { getFilePlugin } from "@react-pdf-viewer/get-file";
import DefaultPdf from "../assets/blank.pdf";
import { base64_arraybuffer } from "../services/utilityServices";
import styles from "./Signer.module.css";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import "@react-pdf-viewer/drop/lib/styles/index.css";
import { Checkbox, Modal, Spin } from "antd";
import type { CheckboxChangeEvent } from "antd/es/checkbox";

import Moveable from "react-moveable";
import { ErrorModalService } from "../components/ErrorHandling/ErrorModalService";
import SigningWizard from "./SigningWizard/SigningWizard";
import SigImageTemplateEN from "../images/sigImageTemplate_EN.png";
import RestartVideoIdent from "../components/RestartVideoIdentComponent/RestartVideoIdent";
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";
import { LoadingOutlined } from "@ant-design/icons";
import { applicationSettings, REDIRECT_URL } from "../appsettings";
import i18next from "i18next";
import SignatureParametersContext from "../store/SignatureParametersContext";
import SignatureMoveable from "../components/SignatureMoveable/SignatureMoveable";
import PdfViewerTopToolbar from "../components/PdfViewerTopToolbar/PdfViewerTopToolbar";
import SignContextMenu from "../components/SignContextMenu/SignContextMenu";
import IntroTooltipContext from "../store/IntroTooltipContext";
import IntroModal from "../components/IntroModal/IntroModal";
import PdfViewerBottomToolbar from "../components/PdfViewerBottomToolbar/PdfViewerBottomToolbar";
import { signatureFrameReducer } from "../store/SignatureFrameReducer";
import { FirstTimeVisitorModal } from "../components/FirstTimeVisitorModal/FirstTimeVisitorModal";
import axios from "axios";

//reject modal
const rejectInfo =  (rejectUrl: string) => {
  Modal.confirm({
    title: <div>{i18next.t("reject_signing_header")}<hr/></div>,
    content: (
      <div>
        <p>{i18next.t("reject_signing_info")}</p>
      </div>
    ),
    async onOk() {
      await axios.get(rejectUrl).then(() => {
        rejectSuccessInfo();
      }).catch(_ => {
        rejectUnsuccessInfo();
      })
    },
    okText: i18next.t('confirm_label_lower'),
    cancelText: i18next.t('cancel_label_lower'),
    closable: true,
    okButtonProps: { danger: true },
    cancelButtonProps: { danger: true, type: "default" },
    autoFocusButton: null,
  });
};

//reject successful modal
const rejectSuccessInfo = () => {
  Modal.success({
    title: i18next.t("reject_success"),
    content: (
        <div>
          <p>{i18next.t("reject_success_text")}</p>
        </div>
    ),
    onOk() {
      setTimeout(() => {
        window.close();
      }, 300);

      setTimeout(() => {
        window.location.replace("https://vizibit.eu");
      }, 1500);
    },
    okText: "OK",
    okButtonProps: { danger: true },
    cancelButtonProps: { danger: true, type: "default" },
    autoFocusButton: null,
  });
};

//reject unsuccessful modal
const rejectUnsuccessInfo = () => {
  Modal.error({
    title: i18next.t("reject_failed"),
    content: (
        <div>
          <p>{i18next.t("reject_failed_text")}</p>
        </div>
    ),
    closable: true,
    okButtonProps: { danger: true },
    cancelButtonProps: { danger: true, type: "default" },
    autoFocusButton: null,
  });
};

const Signer = () => {
  //signature parameters & tooltip context
  const signatureParamsCtx = useContext(SignatureParametersContext);
  const tooltipCtx = useContext(IntroTooltipContext);

  //initial variables and state
  const [width, setWidth] = useState<number>(window.innerWidth);
  const isMobileDefaultZoomLevel = width <= 980;
  const isMobile = width <= 622;
  const [signingDrawerState, setSigningDrawerState] = useState({
    open: false,
    startingPage: 0,
    signingWithImage: false,
    addNewProfile: false,
    signWithImageContinue: false,
    signingImageProfile: {},
    restartVideoIdentDrawer: false,
    createdSignatureImage: undefined,
  });
  const [signatureFrame, setSignatureFrame] = useReducer(
    signatureFrameReducer,
    {
      size: [
        150 * (isMobileDefaultZoomLevel ? 0.5 : 1.5),
        75 * (isMobileDefaultZoomLevel ? 0.5 : 1.5),
      ],
      translate: [
        100 * (isMobileDefaultZoomLevel ? 0.5 : 1.5),
        100 * (isMobileDefaultZoomLevel ? 0.5 : 1.5),
      ],
      rotate: 0,
      transformOrigin: "50% 50%",
      scale: isMobileDefaultZoomLevel ? 0.5 : 1.5,
      opacity: "100%",
      signaturePage: 1,
    }
  );
  const [moveableTarget, setMoveableTarget] = useState<HTMLElement | undefined>(
    undefined
  );
  const [addSignature, setAddSignature] = useState(false);
  const [loadingState, setLoadingState] = useState(true);
  const [errorStatePdf, setErrorStatePdf] = useState(false);
  const [signWithoutImageModal, setSignWithoutImageModal] = useState({
    open: false,
    rememberChoice: false,
  });
  const [firstTimeVisitorModal, setFirstTimeVisitorModal] = useState(false);

  //pdf viewer instances
  const getFilePluginInstance = getFilePlugin({});
  const toolbarPluginInstance = toolbarPlugin();
  const { Toolbar } = toolbarPluginInstance;

  //variables
  const signatureImageToSignWithData = useRef<object>({});
  const page = useRef<number>(0);
  const moveableRef = useRef<Moveable>(null);
  const sigCanvas = useRef({});

  //icon for spinner
  const antIcon = (
    <LoadingOutlined style={{ fontSize: 48, color: "white" }} spin />
  );

  //getting signature page element, and setting bounds for sig image
  let signaturePageElement = document.getElementById("signature-page");
  let bounds = signaturePageElement?.getBoundingClientRect();

  //getting info of window width
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  //effect for initial checks
  useEffect(() => {
    //if pdf file is not defined, set error state and dont show toolbar
    if (signatureParamsCtx.pdfFile === undefined) {
      setErrorStatePdf(true);
      window.location.replace(window.location.origin);
    } else {
      //check if we need to show tooltips
      if (applicationSettings.TOOLTIP_COMPONENT_ENABLED) {
        if (localStorage.getItem("eSignLite_ShowTooltips")) {
          if (localStorage.getItem("eSignLite_ShowTooltips") === "true") {
            tooltipCtx.setShowTooltips(true);
            tooltipCtx.setTooltipStep(1);
            tooltipCtx.setShowIntroModal(false);
          } else {
            tooltipCtx.setShowTooltips(false);
            tooltipCtx.setTooltipStep(0);
            tooltipCtx.setShowIntroModal(false);
          }
        } else {
          setTimeout(() => {
            tooltipCtx.setShowIntroModal(true);
          }, 1500);
        }
      }
    }

    //check for restart video identification state
    if (
      signatureParamsCtx.targetIdentUrl !== null &&
      signatureParamsCtx.targetIdentUrl !== undefined
    ) {
      setSigningDrawerState((prevState) => ({
        ...prevState,
        restartVideoIdentDrawer: true,
      }));
    }

    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  //const handlers
  //handler for warn about signing without image checkbox
  const handleRememberChoiceCheckbox = useCallback((e: CheckboxChangeEvent) => {
    setSignWithoutImageModal((prevState) => ({
      ...prevState,
      rememberChoice: e.target.checked,
    }));

    localStorage.setItem(
      "eSignLiteSignatureImageWarning",
      e.target.checked.toString()
    );
  }, []);

  //handler for showing modal with warning about signature image
  const showSignWithoutImageModal = useCallback(() => {
    let warnAboutImage = localStorage.getItem("eSignLiteSignatureImageWarning");

    if (warnAboutImage && warnAboutImage === "true") {
      signingDrawerWithoutImageStateHandler();
    } else {
      setSignWithoutImageModal((prevState) => ({
        ...prevState,
        open: true,
      }));
    }
  }, []);

  //closing Signing without sig. image warning modal handler
  const closeSignWithoutImageModal = useCallback(() => {
    setSignWithoutImageModal((prevState) => ({
      ...prevState,
      open: false,
      rememberChoice: false,
    }));
  }, []);

  //handler for signing the document with created sig. image
  const signWithCreatedSignatureImageHandler = (
    data: any,
    createdImage: any
  ) => {
    signatureImageToSignWithData.current = data;
    addSigImageInitial();

    setSigningDrawerState((prevState) => ({
      ...prevState,
      open: false,
      startingPage: 0,
      signingWithImage: false,
      addNewProfile: false,
      createdSignatureImage: createdImage,
    }));
  };

  //handler for signing the document after sig. image was created & placed on the document
  const signWithImage = () => {
    setSigningDrawerState((prevState) => ({
      ...prevState,
      open: true,
      startingPage: 2,
      signingImageProfile: signatureImageToSignWithData.current,
      signWithImageContinue: true,
    }));
  };

  //handler for signing the document without sig. image (sign button)
  const signingDrawerWithoutImageStateHandler = () => {
    if (isMobile) {
      localStorage.setObj("eSignLiteDefaultCTA", "1");
    }

    tooltipCtx.setTooltipStep(3);

    setSigningDrawerState((prevState) => ({
      ...prevState,
      open: !signingDrawerState.open,
      startingPage: 1,
    }));
  };

  //handler for signing the document with sig. image (sig. image button)
  const signingDrawerWithImageStateHandler = () => {
    setSigningDrawerState((prevState) => ({
      ...prevState,
      open: !signingDrawerState.open,
      startingPage: 0,
      signingWithImage: true,
    }));

    tooltipCtx.setTooltipStep(2);
  };

  //handler for closing signing drawer
  const closeSigningDrawer = () => {
    setSigningDrawerState((prevState) => ({
      ...prevState,
      open: false,
      startingPage: 0,
      signingWithImage: false,
      addNewProfile: false,
      signWithImageContinue: false,
      signingImageProfile: {},
    }));

    tooltipCtx.setTooltipStep(1);
  };

  //logic which happens of every page change - ensuring that signature moveable is correctly set
  const changePageHandler = (e: any) => {
    page.current = e.currentPage;
    //fix for a bug where on a multipage doc signature field would lose it's connection to moveable, because page is not rendered (that's how PDF viewer works)
    let signatureFieldElement = document.getElementById("signature-field");
    if (
      signatureFieldElement &&
      e.currentPage === signatureFrame.signaturePage - 1
    ) {
      setTimeout(() => {
        setMoveableTarget(document.getElementById("signature-field")!);
      }, 200);
    }
  };

  //logic for adding sig. image to the document canvas
  const addSigImageInitial = () => {
    if (!addSignature) {
      setSignatureFrame({
        type: "ON_PAGE_CHANGE",
        signaturePage: page.current + 1,
      });
      setAddSignature(true);

      tooltipCtx.setTooltipStep(5);

      setTimeout(() => {
        let signatureImageElement = document.getElementById("signature-field");
        if (signatureImageElement) {
          signatureImageElement.scrollIntoView({
            behavior: "smooth",
            block: "center",
          });
        }
      }, 500);
    }

    if (addSignature) {
      setTimeout(() => {
        setSignatureFrame({
          type: "ON_PAGE_CHANGE",
          signaturePage: page.current + 1,
        });
        setAddSignature(true);
        setTimeout(() => {
          let signatureImageElement =
            document.getElementById("signature-field");
          if (signatureImageElement) {
            signatureImageElement.scrollIntoView({
              behavior: "smooth",
              block: "center",
            });
          }
        }, 500);
      }, 200);
    }
  };

  //help tooltip handler
  const handleHideTooltipButton = () => {
    tooltipCtx.setTooltipStep(tooltipCtx.currentTooltipStep! + 1);
  };

  //removing sig. image from doc. canvas
  const removeSignatureHandler = () => {
    moveableRef.current?.destroy();
    setAddSignature(false);
    setSignatureFrame({ type: "ON_REMOVE" });
    tooltipCtx.setTooltipStep(1);
  };

  const handleDocumentLoad = async (e: DocumentLoadEvent) => {
    let base64Pdf = await base64_arraybuffer(e.file.data);
    signatureParamsCtx.pdfFileBase64 = base64Pdf;

    setTimeout(() => {
      setLoadingState(false);

      let doesUserHaveSignatureProfileWithImage = localStorage.getItem(
        "eSignLiteSignatures"
      );

      let doesUserHaveSignatureProfileWithoutImage = localStorage.getItem(
          "eSignLiteSignaturesOnly"
      );

      if (!doesUserHaveSignatureProfileWithImage && !doesUserHaveSignatureProfileWithoutImage) {
        setFirstTimeVisitorModal(true);
      }
    }, 500);

    setTimeout(() => {
      let getSignaturePageHtmlElement =
        document.getElementById("signature-page");
      if (getSignaturePageHtmlElement) {
        setSignatureFrame({
          type: "ON_LOAD_TRANSLATE",
          translate: [
            getSignaturePageHtmlElement.getBoundingClientRect().width / 2 -
              signatureFrame.size[0] / 2,
            getSignaturePageHtmlElement.getBoundingClientRect().height / 2 -
              signatureFrame.size[1] / 2,
          ],
        });
      }
    }, 1000);
  };

  const renderToolbar = (Toolbar: (props: ToolbarProps) => ReactElement) => (
    <>
      <Toolbar>
        {(slots: ToolbarSlot) => {
          return (
            <PdfViewerTopToolbar
              slots={slots}
              addSignature={addSignature}
              isMobile={isMobile}
            />
          );
        }}
      </Toolbar>
    </>
  );

  const renderError = (error: LoadError) => {
    let message = "";
    switch (error.name) {
      case "InvalidPDFException":
        new ErrorModalService(13).showErrorModal();
        message = "The document is invalid or corrupted";
        setErrorStatePdf(true);
        break;
      case "MissingPDFException":
        message = "The document is missing";
        setErrorStatePdf(true);
        break;
      case "UnexpectedResponseException":
        setErrorStatePdf(true);
        message = "Unexpected server response";
        break;
      default:
        message = "Cannot load the document";
        setErrorStatePdf(true);
        break;
    }

    return (
      <div
        style={{
          alignItems: "center",
          display: "flex",
          height: "100%",
          justifyContent: "center",
          minHeight: "250px",
        }}
      >
        <div
          style={{
            backgroundColor: "#e53e3e",
            borderRadius: "0.25rem",
            color: "#fff",
            padding: "0.5rem",
          }}
        >
          {message}
        </div>
      </div>
    );
  };

  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    renderToolbar,
    sidebarTabs: (defaultTabs) => [],
  });

  const zoom = (e: ZoomEvent) => {
    setSignatureFrame({ type: "ON_ZOOM", scale: e.scale, opacity: 0 });
    setTimeout(() => {
      setSignatureFrame({ type: "ON_CHANGE_OPACITY", opacity: 1 });
    }, 500);
  };

  const renderPage: RenderPage = (props: RenderPageProps) => {
    if (props.pageIndex === signatureFrame.signaturePage - 1) {
      return (
        <div id="signature-page" style={{ width: "100%", height: "100%" }}>
          {props.canvasLayer.children}
          {addSignature && (
            <div style={{ opacity: signatureFrame.opacity }}>
              <SignContextMenu
                signWithImage={signWithImage}
                removeSigHandler={removeSignatureHandler}
              >
                <img
                  id="signature-field"
                  className={styles.Signator_SignatureFieldContainer}
                  src={
                    signingDrawerState.createdSignatureImage ??
                    SigImageTemplateEN
                  }
                  style={{
                    height: `${signatureFrame.size[1]}px`,
                    width: `${signatureFrame.size[0]}px`,
                    transform: `translate(${signatureFrame.translate[0]}px, ${signatureFrame.translate[1]}px)`,
                    zIndex: "1",
                    position: "relative",
                  }}
                ></img>
              </SignContextMenu>
              <SignatureMoveable
                addSignature={addSignature}
                signatureFrame={signatureFrame}
                removeSignatureHandler={removeSignatureHandler}
                width={width}
                setSignatureFrame={setSignatureFrame}
                renderProps={props}
              />
            </div>
          )}
          <div style={{ display: "none" }}>
            {props.annotationLayer.children}
            {props.textLayer.children}
          </div>
        </div>
      );
    } else {
      return (
        <>
          {props.canvasLayer.children}
          <div style={{ display: "none" }}>
            {props.annotationLayer.children}
            {props.textLayer.children}
          </div>
        </>
      );
    }
  };

  //signature image function which sets correct offset on Y axis
  function getOffsetY() {
    if (bounds?.height) {
      let boundsHeight = bounds?.height / signatureFrame.scale;
      let imageOffsetY =
        parseInt(signatureFrame.translate[1]) / signatureFrame.scale;
      let imageHeight = signatureFrame.size[1] / signatureFrame.scale;
      return boundsHeight - imageOffsetY - imageHeight;
    } else {
      return 0;
    }
  }

  return (
    <div className={styles.PdfSignerOverlay}>
      {signingDrawerState.restartVideoIdentDrawer && (
        <RestartVideoIdent
          restartVideoIdentDrawer={signingDrawerState.restartVideoIdentDrawer}
          closeRestartVideoIdentDrawer={() => {
            setSigningDrawerState((prevState) => ({
              ...prevState,
              restartVideoIdentDrawer: false,
            }));
            signatureParamsCtx.targetIdentUrl = undefined;
          }}
        />
      )}
      <SigningWizard
        signingDrawerOpen={signingDrawerState.open}
        signingWithSignatureImage={signingDrawerState.signingWithImage}
        closeSigningDrawer={closeSigningDrawer}
        startingStep={signingDrawerState.startingPage}
        removeSignatureHandler={removeSignatureHandler}
        signWithCreatedSignatureImageHandler={
          signWithCreatedSignatureImageHandler
        }
        signWithImageContinue={signingDrawerState.signWithImageContinue}
        signingImageProfile={signatureImageToSignWithData.current ?? undefined}
        signatureImage={
          addSignature
            ? {
                page: signatureFrame.signaturePage - 1,
                offsetX: signatureFrame.translate[0] / signatureFrame.scale,
                offsetY: getOffsetY(),
                width: signatureFrame.size[0] / signatureFrame.scale,
                height: signatureFrame.size[1] / signatureFrame.scale,
              }
            : undefined
        }
      />
      <div className={styles.PdfSignerViewer}>
        <>
          <Worker workerUrl={pdfjsWorker}>
            <div
              className="rpv-core__viewer"
              style={{
                display: "flex",
                height: "100%",
                flexDirection: "column",
                position: "relative",
              }}
            >
              <PdfViewerBottomToolbar
                isMobile={isMobile}
                hideTooltip={handleHideTooltipButton}
                signingDrawerWithoutImageStateHandler={
                  signingDrawerWithoutImageStateHandler
                }
                signWithoutImageModalHandler={showSignWithoutImageModal}
                signWithImageHandler={signingDrawerWithImageStateHandler}
                signWithImage={signWithImage}
                addSigImageInitial={addSigImageInitial}
                isSigAdded={addSignature}
                deleteSigImage={removeSignatureHandler}
                showRejectHandler={(rejectUrl: string) => rejectInfo(rejectUrl)}
                errorStatePdf={errorStatePdf}
                loadingState={loadingState}
              />
              <Spin
                style={{
                  marginTop: "250px",
                  width: "100%",
                  display: loadingState ? "flex" : "none",
                  justifyContent: "center",
                }}
                indicator={antIcon}
              />
              <div
                style={{
                  flex: 1,
                  overflow: "hidden",
                  visibility: loadingState ? "hidden" : "visible",
                }}
              >
                <Viewer
                  fileUrl={signatureParamsCtx.pdfFile ?? DefaultPdf}
                  plugins={[
                    defaultLayoutPluginInstance,
                    getFilePluginInstance as Plugin,
                  ]}
                  theme={{ theme: "light" }}
                  defaultScale={
                    isMobileDefaultZoomLevel ? SpecialZoomLevel.PageFit : 1.5
                  }
                  onPageChange={changePageHandler}
                  onDocumentLoad={handleDocumentLoad}
                  renderPage={renderPage}
                  renderError={renderError}
                  onZoom={zoom}
                ></Viewer>
              </div>
            </div>
          </Worker>
          <Modal
            visible={signWithoutImageModal.open}
            title={i18next.t("signature_image_title")}
            footer={null}
            onCancel={closeSignWithoutImageModal}
          >
            <div>
              <div
                style={{
                  fontSize: "15px",
                  fontWeight: "bold",
                  textAlign: "center",
                  fontFamily: "OpenSans",
                  marginBottom: "5px",
                }}
              >
                {i18next.t("no_sig_image_info_one")}
              </div>
              <div
                style={{
                  fontSize: "15px",
                  textAlign: "center",
                  fontFamily: "OpenSans",
                }}
              >
                {i18next.t("no_sig_image_info_two")}
                <b
                  style={{ color: "rgb(98, 61, 145)", cursor: "pointer" }}
                  onClick={() => {
                    closeSignWithoutImageModal();
                    signingDrawerWithImageStateHandler();
                  }}
                >
                  {i18next.t("no_sig_image_info_three")}
                </b>{" "}
                {i18next.t("no_sig_image_info_four")}
              </div>
              <div className={styles.Signator_SignWithoutImageModalButtons}>
                <div
                  className={styles.Signator_SignWithoutImageModalButtonOne}
                  onClick={() => {
                    if (signWithoutImageModal.rememberChoice === true) {
                      localStorage.setItem(
                        "eSignLiteSignatureImageWarning",
                        "true"
                      );
                    }
                    closeSignWithoutImageModal();
                    signingDrawerWithoutImageStateHandler();
                  }}
                >
                  {i18next.t("continue_without_img")}
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "20px",
                }}
              >
                <Checkbox
                  onChange={handleRememberChoiceCheckbox}
                  checked={signWithoutImageModal.rememberChoice}
                >
                  {i18next.t("dont_ask_again")}
                </Checkbox>
              </div>
            </div>
          </Modal>
          <IntroModal initialStep={1} />
          <FirstTimeVisitorModal
            closeModal={() => setFirstTimeVisitorModal(false)}
            firstName={signatureParamsCtx.firstName}
            lastName={signatureParamsCtx.lastName}
            showModal={firstTimeVisitorModal}
            phoneNumber={signatureParamsCtx.phoneNumber}
            jurisdiction={signatureParamsCtx.jurisdiction}
          />
        </>
      </div>
    </div>
  );
};

export default Signer;
