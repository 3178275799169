import React, { useContext, useEffect, useRef, useState } from "react";
import styles from "../SigningWizard.module.css";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import WarningIcon from "@mui/icons-material/Warning";
import BrowserUpdatedIcon from "@mui/icons-material/BrowserUpdated";
import CreditCardOutlinedIcon from "@mui/icons-material/CreditCardOutlined";
import ForwardToInboxOutlinedIcon from "@mui/icons-material/ForwardToInboxOutlined";
import CancelIcon from "@mui/icons-material/Cancel";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import { Drawer, Spin } from "antd";
import { Fade } from "react-awesome-reveal";
import { useFormState } from "react-final-form";
import { FormModel } from "../../../services/signingModel";
import PdfSignerComponent, {
  Jurisdiction,
  SignatureImage,
  SignatureLevel,
  SignatureOptions,
  SignatureProvider,
  SignatureProviderData,
  SwisscomRequestData,
} from "../../../components/SigningComponent/SigningComponent";
import {
  base64toBlob,
  blobPdfFromBase64String,
  splitBase64,
  toUint8Array,
} from "../../../services/utilityServices";
import { ErrorModalService } from "../../../components/ErrorHandling/ErrorModalService";
import { saveAs } from "file-saver";
import i18next from "i18next";
import { useNavigate } from "react-router-dom";
import { applicationSettings, BASE_URL } from "../../../appsettings";
import SignatureParametersContext from "../../../store/SignatureParametersContext";
import axios from "axios";

const StepThree = (props: any) => {
  const formState = useFormState();

  const navigation = useNavigate();

  const [stepThreeState, setStepThreeState] = useState({
    loading: true,
    showSpinner: true,
    errorState: false,
    errorMessage: "",
    signedFile: undefined,
    secondaryDrawerOpen: false,
    secondaryDrawerVideoIdent: false,
    secondaryDrawerBilling: false,
    showRetryButton: false,
    remainingAmountOfCredits: undefined,
    remainingAmountSpinner: false,
    remainingCreditsChecked: false,
  });
  const [widthForDrawer, setWidthForDrawer] = useState<number>(
    window.innerWidth
  );

  const signatureParamsCtx = useContext(SignatureParametersContext);
  const remainingCreditsChecked = useRef<boolean>(false);

  //getting info of window width
  function handleWindowSizeChange() {
    setWidthForDrawer(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  async function listenForSigningResult(event: any) {
    if (event.data.type === "SIGNATOR_SIGNING_ERROR_VIDEO_IDENT") {
      setStepThreeState((prevState) => ({
        ...prevState,
        loading: false,
        showSpinner: false,
        errorState: true,
        errorMessage: event.data.error,
        signedFile: undefined,
        showRetryButton: false,
      }));
    }
    if (event.data.type === "SIGNATOR_SIGNING_ERROR_NO_CREDITS") {
      setStepThreeState((prevState) => ({
        ...prevState,
        loading: true,
        showSpinner: false,
        errorState: false,
        errorMessage: event.data.error,
        signedFile: undefined,
        secondaryDrawerOpen: true,
        secondaryDrawerVideoIdent: false,
        secondaryDrawerBilling: true,
        showRetryButton: true,
      }));
    }
    if (event.data.type === "SIGNATOR_SIGNING_ERROR") {
      setStepThreeState((prevState) => ({
        ...prevState,
        loading: false,
        showSpinner: false,
        errorState: true,
        errorMessage: event.data.error,
        signedFile: undefined,
        showRetryButton: true,
      }));
    }
    if (event.data.type === "SIGNATOR_NO_CREDITS") {
      setStepThreeState((prevState) => ({
        ...prevState,
        loading: false,
        showSpinner: false,
        errorState: true,
        errorMessage: event.data.error,
        signedFile: undefined,
        showRetryButton: false,
        remainingAmountOfCredits: event.data.walletAmount ?? undefined,
      }));
    }
    if (event.data.type === "SIGNATOR_SIGNING_SUCCESS") {
      signatureParamsCtx.isDocumentSigned = true;
      if (
        !signatureParamsCtx.comingFromThirdParty &&
        remainingCreditsChecked.current === false
      ) {
        remainingCreditsChecked.current = true;
        setStepThreeState((prevState) => ({
          ...prevState,
          remainingAmountSpinner: true,
        }));

        await axios
          .post(BASE_URL + "/v/1/product_provisioning/available_credits", {
            amount: "1",
            signatureToken: null,
            phoneNumber: formState.values["txtMobitel"],
            signatureInformation: {
              signatureProvider: formState.values["txtSignatureProvider"],
              jurisdictionType: formState.values["txtSignatureStandard"],
              signatureLevel: formState.values["txtSignatureLevel"],
              productType: 0,
            },
          })
          .then((res) => {
            setStepThreeState((prevState) => ({
              ...prevState,
              loading: false,
              showSpinner: false,
              errorState: false,
              signedFile: event.data.signedFile,
              remainingAmountSpinner: false,
              remainingAmountOfCredits: res.data?.RemainingProducts,
            }));
          })
          .catch((e) => {
            console.log(e);
            setStepThreeState((prevState) => ({
              ...prevState,
              loading: false,
              showSpinner: false,
              errorState: false,
              signedFile: event.data.signedFile,
            }));
          });
      } else {
        setStepThreeState((prevState) => ({
          ...prevState,
          loading: false,
          showSpinner: false,
          errorState: false,
          signedFile: event.data.signedFile,
        }));
      }
    }
    if (event.data.type === "SIGNATOR_BILLING_PURCHASE_COMPLETED") {
      setStepThreeState((prevState) => ({
        ...prevState,
        secondaryDrawerOpen: false,
        secondaryDrawerVideoIdent: false,
        secondaryDrawerBilling: false,
        showRetryButton: true,
      }));
    }
    if (event.data.status === "CLOSE_VIDEO_IDENT_IFRAME") {
      setStepThreeState((prevState) => ({
        ...prevState,
        errorState: false,
        errorMessage: event.data.error,
        signedFile: undefined,
        showSpinner: false,
        secondaryDrawerOpen: false,
        secondaryDrawerVideoIdent: false,
        secondaryDrawerBilling: false,
        showRetryButton: true,
      }));
    }
    if (event.data.type === "SIGNATOR_VIDEO_IDENT_SUCCESS_FINISHED") {
      setStepThreeState((prevState) => ({
        ...prevState,
        secondaryDrawerOpen: false,
        secondaryDrawerVideoIdent: false,
        secondaryDrawerBilling: false,
        showSpinner: false,
        showRetryButton: true,
      }));
    }
  }

  window.addEventListener("message", listenForSigningResult, false);

  useEffect(() => {
    if (props.signWithImageContinue) {
      props.addProfileStateHandler(false);

      formState.values["txtMobitel"] =
        props.signingImageProfile.txtMobitel! ?? "";
      formState.values["txtSignatureLevel"] =
        props.signingImageProfile.txtSignatureLevel ?? "";
      formState.values["txtSignatureProvider"] =
        props.signingImageProfile.txtSignatureProvider ?? "";
      formState.values["txtSignatureStandard"] =
        props.signingImageProfile.txtSignatureStandard ?? "";
      formState.values["txtName"] = props.signingImageProfile.txtName ?? "";
      formState.values["txtCompanyName"] =
        props.signingImageProfile.txtCompanyName ?? "";
      formState.values["txtDepartment"] =
        props.signingImageProfile.txtDepartment ?? "";
      formState.values["txtPosition"] =
        props.signingImageProfile.txtPosition ?? "";
      formState.values["txtLogo"] = props.signingImageProfile.txtLogo ?? "";
      formState.values["txtSignatureImagePicture"] =
        props.signingImageProfile.txtSignatureImagePicture ?? "";

      submitForm();
    } else {
      props.addProfileStateHandler(false);

      submitForm();
    }

    return () => {
      setStepThreeState((prevState) => ({
        ...prevState,
        loading: true,
        showSpinner: true,
        errorState: false,
        errorMessage: "",
        signedFile: undefined,
        secondaryDrawerOpen: false,
        secondaryDrawerVideoIdent: false,
        secondaryDrawerBilling: false,
        showRetryButton: false,
        remainingAmountOfCredits: undefined,
      }));
    };
  }, []);

  const closeWindowHandler = () => {
    setTimeout(() => {
      window.close();
    }, 300);

    setTimeout(() => {
      window.location.replace("https://vizibit.eu");
    }, 1500);
  };

  const saveDocumentHandler = (signedFile: any) => {
    if (signedFile !== undefined) {
      let blob = base64toBlob(signedFile, "application/pdf");
      saveAs(blob, "document_signed.pdf");
    } else {
      throw new Error(i18next.t("doc_not_available"));
    }
  };

  const backToDocumentHandler = async () => {
    props.removeSignatureHandler();
    props.closeDrawer();
    let blobPdf = (await blobPdfFromBase64String(
      stepThreeState.signedFile!
    )) as File;
    let convertPdf = await toUint8Array(blobPdf);
    signatureParamsCtx.pdfFile = convertPdf;
    signatureParamsCtx.rejectUrl = undefined;
    signatureParamsCtx.signatureToken = undefined;
    signatureParamsCtx.storageId = undefined;
    signatureParamsCtx.isDocumentSigned = false;
    if (signatureParamsCtx.comingFromThirdParty) {
      signatureParamsCtx.backToDocAfterThirdPartySign = true;
    }
    navigation("/sign");
  };

  const submitForm = async () => {
    setTimeout(() => {
      setStepThreeState((prevState) => ({
        ...prevState,
        showSpinner: false,
      }));
    }, 1000);

    try {
      setStepThreeState((prevState) => ({
        ...prevState,
        showRetryButton: false,
      }));
      const { ...rest } = formState.values as FormModel;

      if (applicationSettings.SIGNING_OPTIONS_PREDEFINED === true) {
        if (
          (rest["txtSignatureStandard"] as Jurisdiction) !==
          applicationSettings.DEFAULT_PREDEFINED_STANDARD
        ) {
          setStepThreeState((prevState) => ({
            ...prevState,
            loading: false,
            showSpinner: false,
            errorState: true,
            errorMessage: `Invalid signature standard. Only ${applicationSettings.DEFAULT_PREDEFINED_STANDARD.toString()} standard is supported.`,
          }));
          return;
        } else if (
          rest["txtSignatureLevel"] !==
          applicationSettings.DEFAULT_PREDEFINED_LEVEL
        ) {
          setStepThreeState((prevState) => ({
            ...prevState,
            loading: false,
            showSpinner: false,
            errorState: true,
            errorMessage: `Invalid signature level.  Only ${applicationSettings.DEFAULT_PREDEFINED_LEVEL.toString()} level is supported.`,
          }));
          return;
        }
      }

      let signature = new PdfSignerComponent(
        signatureParamsCtx.pdfFileBase64!,
        new SignatureOptions("Reason", "Location"),
        rest["txtSignatureProvider"] as SignatureProvider,
        new SignatureProviderData(
          new SwisscomRequestData(
            rest["txtMobitel"]!,
            rest["txtSignatureStandard"] as Jurisdiction,
            rest["txtSignatureLevel"] as SignatureLevel
          )
        ),
        rest["txtSignatureImagePicture"]
          ? new SignatureImage(
              props.signatureImage.page,
              props.signatureImage.offsetX,
              props.signatureImage.offsetY,
              props.signatureImage.height,
              props.signatureImage.width,
              splitBase64(rest["txtSignatureImagePicture"]!),
              rest["txtName"] ?? "",
              rest["txtPosition"] ?? "",
              rest["txtDepartment"] ?? "",
              rest["txtCompanyName"] ?? "",
              rest["txtLogo"] ?? null
            )
          : undefined,
        signatureParamsCtx.signatureToken ?? undefined,
        undefined,
        signatureParamsCtx.targetIdentUrl ?? undefined,
        signatureParamsCtx.prepaid ?? undefined,
        i18next.language
      );

      const result = await signature.signDocument();

      if (result === "ERROR") {
        setStepThreeState((prevState) => ({
          ...prevState,
          loading: false,
          showSpinner: false,
          errorState: true,
        }));
      }
    } catch (e) {
      setStepThreeState((prevState) => ({
        ...prevState,
        showRetryButton: true,
      }));
      new ErrorModalService(5).showErrorModal();

      console.log(e);
    }
  };

  const signNewDocumentHandler = () => {
    window.location.replace(window.location.origin);
    signatureParamsCtx.isDocumentSigned = false;
  };

  const retrySignatureHandler = () => {
    setStepThreeState((prevState) => ({
      ...prevState,
      loading: true,
      errorState: false,
      errorMessage: "",
      signedFile: undefined,
      secondaryDrawerOpen: false,
      secondaryDrawerVideoIdent: false,
      secondaryDrawerBilling: false,
      showRetryButton: false,
      remainingAmountOfCredits: undefined,
    }));

    submitForm();
  };

  return (
    <Fade>
      <Drawer
        title={
          stepThreeState.secondaryDrawerBilling
            ? i18next.t("purchase_credits")
            : i18next.t("identification")
        }
        width={
          widthForDrawer < 1200 ? (widthForDrawer < 700 ? "95%" : "70%") : "50%"
        }
        closable={true}
        onClose={() =>
          setStepThreeState((prevState) => ({
            ...prevState,
            secondaryDrawerOpen: false,
            secondaryDrawerVideoIdent: false,
            secondaryDrawerBilling: false,
            showSpinner: false,
            loading: true,
            showRetryButton: true,
            remainingAmountOfCredits: undefined,
          }))
        }
        placement="right"
        open={stepThreeState.secondaryDrawerOpen}
        visible={stepThreeState.secondaryDrawerOpen}
        destroyOnClose={true}
      >
        <div
          id={"signator-secondary-drawer"}
          style={{ height: "800px", maxHeight: "800px" }}
        ></div>
      </Drawer>
      {stepThreeState.loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          {stepThreeState.showSpinner ? (
            <Spin size="large" style={{ marginTop: "150px" }} />
          ) : (
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  color: "#623d91",
                  fontSize: "20px",
                  fontWeight: "bold",
                  marginTop: "35px",
                  textAlign: "center",
                }}
              >
                {i18next.t("digital_sig_confirmation")}
              </div>
              <div className={styles.Signator_Signature_Options_Info}>
                <div className={styles.Signator_SignatureOptionsInfoText}>
                  <div style={{ width: "50%" }}>
                    {i18next.t("sig_standard")}:
                  </div>
                  <div style={{ fontWeight: "bold", color: "#c2131c" }}>
                    {formState.values["txtSignatureStandard"]}
                  </div>
                </div>
                <div className={styles.Signator_SignatureOptionsInfoText}>
                  <div style={{ width: "50%" }}>
                    {i18next.t("sig_provider")}:
                  </div>
                  <div style={{ fontWeight: "bold", color: "#c2131c" }}>
                    {formState.values["txtSignatureProvider"] === "SwisscomAis"
                      ? "Swisscom Trustservice"
                      : formState.values["txtSignatureProvider"]}
                  </div>
                </div>
                <div className={styles.Signator_SignatureOptionsInfoText}>
                  <div style={{ width: "50%" }}>{i18next.t("sig_level")}:</div>
                  <div style={{ fontWeight: "bold", color: "#c2131c" }}>
                    {formState.values["txtSignatureLevel"]}
                  </div>
                </div>
                <div className={styles.Signator_SignatureOptionsInfoText}>
                  <div style={{ width: "50%" }}>
                    {i18next.t("phone_number")}:
                  </div>
                  <div style={{ fontWeight: "bold", color: "#c2131c" }}>
                    {formState.values["txtMobitel"]}
                  </div>
                </div>
                {stepThreeState.showRetryButton && (
                  <div
                    className={styles.SignatorRetryButton}
                    onClick={retrySignatureHandler}
                  >
                    <DriveFileRenameOutlineIcon
                      style={{ marginRight: "5px" }}
                    />
                    {i18next.t("try_again")}
                  </div>
                )}
              </div>
              <div
                id={"signator_drawer_container"}
                className={styles.Signator_Drawer_Container}
              ></div>
            </div>
          )}
        </div>
      ) : stepThreeState.errorState ? (
        <div className={styles.SignatorStepContainer}>
          <div className={styles.SignatorErrorTitle}>
            {stepThreeState.errorMessage === "NO_CREDITS_ERROR" && (
              <span style={{ color: "#ff8d00" }}>
                {i18next.t("signator_no_funds_title")}
              </span>
            )}
            {stepThreeState.errorMessage === "SIGNING_COMBINATION_ERROR" && (
              <span style={{ color: "#ff8d00" }}>
                {i18next.t("signator_signing_combination_error_title")}
              </span>
            )}
            {stepThreeState.errorMessage === "NO_DIGITAL_IDENT" && (
              <span style={{ color: "#ff8d00" }}>
                {i18next.t("signator_missing_digital_identity")}
              </span>
            )}
            {stepThreeState.errorMessage !== "NO_DIGITAL_IDENT" &&
              stepThreeState.errorMessage !== "NO_CREDITS_ERROR" &&
              stepThreeState.errorMessage !== "SIGNING_COMBINATION_ERROR" &&
              i18next.t("undefined")}
          </div>
          {stepThreeState.errorMessage !== "NO_CREDITS_ERROR" &&
            stepThreeState.errorMessage !== "NO_DIGITAL_IDENT" &&
            stepThreeState.errorMessage !== "SIGNING_COMBINATION_ERROR" && (
              <div className={styles.SignatorSuccessIcon}>
                <ErrorIcon
                  style={{ width: "60%", height: "60%", color: "red" }}
                />
              </div>
            )}
          {stepThreeState.errorMessage === "SIGNING_COMBINATION_ERROR" && (
            <div className={styles.SignatorSuccessIcon}>
              <WarningIcon
                style={{ width: "60%", height: "60%", color: "#ff8d00" }}
              />
            </div>
          )}
          {stepThreeState.errorMessage === "NO_CREDITS_ERROR" && (
            <div className={styles.SignatorSuccessIcon}>
              <WarningIcon
                style={{ width: "60%", height: "60%", color: "#ff8d00" }}
              />
            </div>
          )}
          {stepThreeState.errorMessage === "NO_DIGITAL_IDENT" && (
            <div className={styles.SignatorSuccessIcon}>
              <WarningIcon
                style={{ width: "60%", height: "60%", color: "#ff8d00" }}
              />
            </div>
          )}
          {stepThreeState.errorMessage === "NO_CREDITS_ERROR" && (
            <div
              className={styles.SignatorErrorMessage}
              style={{ color: "#5e5e5e" }}
            >
              {stepThreeState.remainingAmountOfCredits !== undefined && (
                <div style={{ marginBottom: "10px" }}>
                  {i18next.t("signator_remaining_amount")}
                  {stepThreeState.remainingAmountOfCredits}
                  <br />
                </div>
              )}
              {i18next.t("signator_no_credits_message_part_one")} <br />
              <span>
                <a href={"https://vizibit.eu/pricing"} target={"_blank"}>
                  {i18next.t("signator_no_credits_message_part_one_pricing")}
                </a>
              </span>
              <div
                className={styles.SignatorSuccessButtonsContainer}
                style={{ width: "100%", marginTop: "20px" }}
              >
                <a
                  className={styles.SignatorSuccessButton}
                  href={"https://vizibit.eu/pricing/"}
                  target={"_blank"}
                >
                  <CreditCardOutlinedIcon style={{ marginRight: "5px" }} />
                  {i18next.t("order_by_form")}
                </a>
                <a
                  className={styles.SignatorSuccessButton}
                  href={"mailto: order@vizibit.eu"}
                  target={"_blank"}
                >
                  <ForwardToInboxOutlinedIcon style={{ marginRight: "5px" }} />
                  {i18next.t("order_by_email")}
                </a>
              </div>
              <br />
              {i18next.t("signator_no_credits_organization")}
            </div>
          )}
          {stepThreeState.errorMessage === "NO_DIGITAL_IDENT" && (
            <div
              className={styles.SignatorErrorMessage}
              style={{ color: "#5e5e5e" }}
            >
              {i18next.t("signator_no_digital_identity")}
              <br />
              {i18next.t("signator_no_digital_identity_two")}
              <span>
                <a
                  href={"https://vizibit.eu/digital-signatures/#identification"}
                  target={"_blank"}
                >
                  {i18next.t("signator_no_digital_identity_link")}
                </a>
              </span>
            </div>
          )}
          {stepThreeState.errorMessage === "SIGNING_COMBINATION_ERROR" && (
            <div
              className={styles.SignatorErrorMessage}
              style={{ color: "#5e5e5e" }}
            >
              {i18next.t("signator_signing_combination_error_message")}
            </div>
          )}
          {stepThreeState.errorMessage !== "NO_CREDITS_ERROR" &&
            stepThreeState.errorMessage !== "NO_DIGITAL_IDENT" &&
            stepThreeState.errorMessage !== "SIGNING_COMBINATION_ERROR" && (
              <div className={styles.SignatorErrorMessage}>
                {stepThreeState.errorMessage}
              </div>
            )}
          {stepThreeState.showRetryButton &&
            stepThreeState.errorMessage === i18next.t("20016_message") && (
              <div
                className={styles.SignatorRetryButton}
                onClick={retrySignatureHandler}
              >
                <DriveFileRenameOutlineIcon style={{ marginRight: "5px" }} />
                {i18next.t("try_again")}
              </div>
            )}
        </div>
      ) : (
        <div className={styles.SignatorStepContainer}>
          <div className={styles.SignatorSuccessTitle}>
            {i18next.t("success")}
          </div>
          <div className={styles.SignatorSuccessIcon}>
            <CheckCircleIcon
              style={{ width: "60%", height: "60%", color: "#13c2c2" }}
            />
          </div>
          {signatureParamsCtx.comingFromThirdParty && (
            <div
              className={styles.SignatorErrorMessage}
              style={{ color: "#5e5e5e", marginBottom: "20px", width: "80%" }}
            >
              {i18next.t("success_email_text")}
            </div>
          )}
          {!signatureParamsCtx.comingFromThirdParty && (
            <div
              className={styles.SignatorErrorMessage}
              style={{
                color: "#5e5e5e",
                marginBottom: "20px",
                width: "80%",
                marginTop: "20px",
              }}
            >
              {i18next.t("signator_remaining_amount_for_mobile")}
              <br />
              {formState.values["txtMobitel"]}
              {":"}
              {stepThreeState.remainingAmountSpinner ? (
                <span style={{ marginLeft: "5px" }}>
                  <Spin size="small" />
                </span>
              ) : (
                <span style={{ marginLeft: "5px" }}>
                  {stepThreeState.remainingAmountOfCredits}
                </span>
              )}
            </div>
          )}
          <div
            className={styles.SignatorSuccessButtonsContainer}
            style={{ marginTop: "20px" }}
          >
            <div
              className={styles.SignatorSuccessButton}
              onClick={() => saveDocumentHandler(stepThreeState.signedFile)}
            >
              <BrowserUpdatedIcon style={{ marginRight: "5px" }} />
              {i18next.t("download")}
            </div>
            <div
              className={styles.SignatorSuccessButton}
              onClick={backToDocumentHandler}
            >
              <KeyboardReturnIcon style={{ marginRight: "5px" }} />
              {i18next.t("back_to_doc_label")}
            </div>
          </div>
          <div className={styles.SignatorSuccessButtonsContainer}>
            {signatureParamsCtx.comingFromThirdParty &&
              window.innerWidth > 1200 && (
                <div
                  className={styles.SignatorSuccessButton}
                  onClick={closeWindowHandler}
                >
                  <CancelIcon style={{ marginRight: "5px" }} />
                  {i18next.t("close_label")}
                </div>
              )}
            {!signatureParamsCtx.comingFromThirdParty && (
              <div
                className={styles.SignatorSuccessButton}
                onClick={signNewDocumentHandler}
              >
                <DriveFileRenameOutlineIcon style={{ marginRight: "5px" }} />
                {i18next.t("sign_new_doc_label")}
              </div>
            )}
          </div>
        </div>
      )}
    </Fade>
  );
};

export default StepThree;
