import {
  Jurisdiction,
  SignatureLevel,
  SignatureProvider,
} from "./components/SigningComponent/SigningComponent";

interface IApplicationSettings {
  PRODUCTION_BUILD: boolean
  UPLOADING_DISABLED: boolean
  ENABLED_LANGUAGES: Array<string>
  LANGUAGES: Array<{}>
  ZERTES_ENABLED: boolean
  EIDAS_ENABLED: boolean
  LOCAL_COMPONENT_AND_REMOTE_SIGNATURE_OPTION: boolean
  REMOTE_SIGNATURE_OPTION: SignatureLevel
  SWISSCOM_ENABLED: boolean
  CERTILIA_ENABLED: boolean
  CERTILIA_RDD_ENABLED: boolean
  SES_ENABLED: boolean
  AES_ENABLED: boolean
  QES_ENABLED: boolean
  TOOLTIP_COMPONENT_ENABLED: boolean
  IDENT_AND_PAYMENT_SERVICE_ENABLED: boolean
  SIGNING_OPTIONS_PREDEFINED: boolean,
  DEFAULT_PREDEFINED_STANDARD: Jurisdiction
  DEFAULT_PREDEFINED_PROVIDER: SignatureProvider
  DEFAULT_PREDEFINED_LEVEL: SignatureLevel
  PADDLE_VENDOR_ID_PRODUCTION: string
  PADDLE_VENDOR_ID_TEST: string
  PADDLE_PACKAGE_ONE_ID_PRODUCTION: string
  PADDLE_PACKAGE_ONE_ID_TEST: string
  PADDLE_PACKAGE_ONE_TEXT_PRODUCTION: string
  PADDLE_PACKAGE_ONE_TEXT_TEST: string
  PADDLE_PACKAGE_ONE_PRICE_PRODUCTION: string
  PADDLE_PACKAGE_ONE_PRICE_TEST: string
  PADDLE_PACKAGE_TWO_ID_PRODUCTION: string
  PADDLE_PACKAGE_TWO_ID_TEST: string
  PADDLE_PACKAGE_TWO_TEXT_PRODUCTION: string
  PADDLE_PACKAGE_TWO_TEXT_TEST: string
  PADDLE_PACKAGE_TWO_PRICE_PRODUCTION: string
  PADDLE_PACKAGE_TWO_PRICE_TEST: string
  PADDLE_PACKAGE_THREE_ID_PRODUCTION: string
  PADDLE_PACKAGE_THREE_ID_TEST: string
  PADDLE_PACKAGE_THREE_TEXT_PRODUCTION: string
  PADDLE_PACKAGE_THREE_TEXT_TEST: string
  PADDLE_PACKAGE_THREE_PRICE_PRODUCTION: string
  PADDLE_PACKAGE_THREE_PRICE_TEST: string
}

enum AppEnv {
  ON_PREM = "ON_PREM",
  PRODUCTION = "PRODUCTION",
  DEMO = "DEMO",
  DEV = "DEV",
}

export const applicationSettings: IApplicationSettings = {
  PRODUCTION_BUILD: false,
  ENABLED_LANGUAGES: ["hr", "en", "fr", "it", "de"],
  LANGUAGES: [
    {
      code: "hr",
      languageName: "HR",
      country_code: "hr",
    },
    {
      code: "en",
      languageName: "EN",
      country_code: "gb",
    },
    {
      code: "fr",
      languageName: "FR",
      country_code: "fr",
    },
    {
      code: "it",
      languageName: "IT",
      country_code: "it",
    },
    {
      code: "de",
      languageName: "DE",
      country_code: "de",
    },
  ],
  UPLOADING_DISABLED: false,
  ZERTES_ENABLED: true,
  EIDAS_ENABLED: true,
  SWISSCOM_ENABLED: true,
  CERTILIA_ENABLED: false,
  CERTILIA_RDD_ENABLED: false,
  LOCAL_COMPONENT_AND_REMOTE_SIGNATURE_OPTION: false,
  REMOTE_SIGNATURE_OPTION: SignatureLevel.QES,
  SES_ENABLED: true,
  AES_ENABLED: true,
  QES_ENABLED: true,
  TOOLTIP_COMPONENT_ENABLED: false,
  IDENT_AND_PAYMENT_SERVICE_ENABLED: true,
  SIGNING_OPTIONS_PREDEFINED: false,
  DEFAULT_PREDEFINED_STANDARD: Jurisdiction.ZERTES,
  DEFAULT_PREDEFINED_PROVIDER: SignatureProvider.SwisscomAis,
  DEFAULT_PREDEFINED_LEVEL: SignatureLevel.SES,
  PADDLE_VENDOR_ID_PRODUCTION: "141255",
  PADDLE_VENDOR_ID_TEST: "5049",
  PADDLE_PACKAGE_ONE_ID_PRODUCTION: "784394",
  PADDLE_PACKAGE_ONE_ID_TEST: "32227",
  PADDLE_PACKAGE_ONE_TEXT_PRODUCTION: "5 credits",
  PADDLE_PACKAGE_ONE_TEXT_TEST: "10 credits",
  PADDLE_PACKAGE_ONE_PRICE_PRODUCTION: " 10€",
  PADDLE_PACKAGE_ONE_PRICE_TEST: " 10€",
  PADDLE_PACKAGE_TWO_ID_PRODUCTION: "784395",
  PADDLE_PACKAGE_TWO_ID_TEST: "32228",
  PADDLE_PACKAGE_TWO_TEXT_PRODUCTION: "25 credits",
  PADDLE_PACKAGE_TWO_TEXT_TEST: "100 credits",
  PADDLE_PACKAGE_TWO_PRICE_PRODUCTION: " 45€",
  PADDLE_PACKAGE_TWO_PRICE_TEST: " 100€",
  PADDLE_PACKAGE_THREE_ID_PRODUCTION: "784397",
  PADDLE_PACKAGE_THREE_ID_TEST: "32229",
  PADDLE_PACKAGE_THREE_TEXT_PRODUCTION: "25 credits",
  PADDLE_PACKAGE_THREE_TEXT_TEST: "1000 credits",
  PADDLE_PACKAGE_THREE_PRICE_PRODUCTION: " 160€",
  PADDLE_PACKAGE_THREE_PRICE_TEST: " 1000€",
}

let appEnvironment: AppEnv;

appEnvironment = AppEnv.ON_PREM;

export let BASE_URL = "";
export let REDIRECT_URL = "";
export let VIDEO_IDENT_IFRAME_URL = "";

if ((appEnvironment as AppEnv) === AppEnv.DEV) {
  BASE_URL = "https://esign-lite-dev.signator.eu";
  REDIRECT_URL = "https://esign-lite-dev.signator.eu";
  VIDEO_IDENT_IFRAME_URL =
    "https://mobile.signator.hr/signator_video_ident_app/";
} else if ((appEnvironment as AppEnv) === AppEnv.DEMO) {
  BASE_URL = "https://esign-lite-demo.signator.eu";
  REDIRECT_URL = "https://esign-lite-demo.signator.eu";
  VIDEO_IDENT_IFRAME_URL =
    "https://mobile.signator.hr/signator_video_ident_app/";
} else if ((appEnvironment as AppEnv) === AppEnv.PRODUCTION) {
  BASE_URL = "https://esign-lite.signator.eu";
  REDIRECT_URL = "https://esign-lite.signator.eu";
  VIDEO_IDENT_IFRAME_URL =
    "https://mobile.signator.hr/signator_video_ident_app/";
} else if ((appEnvironment as AppEnv) === AppEnv.ON_PREM) {
  BASE_URL = window.location.origin;
  REDIRECT_URL = window.location.origin;
  VIDEO_IDENT_IFRAME_URL =
    "https://mobile.signator.hr/signator_video_ident_app/";
}
