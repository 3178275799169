import React, { useContext } from "react";
import styles from "./PdfViewerBottomToolbar.module.css";
import SignButtonMenu from "../SignButtonMenu/SignButtonMenu";
import SigImageButton from "../SigImageButton/SigImageButton";
import RejectButton from "../RejectButton/RejectButton";
import IntroTooltip from "../IntroTooltip/IntroTooltip";
import IntroTooltipContext from "../../store/IntroTooltipContext";
import SignatureParametersContext from "../../store/SignatureParametersContext";

interface IPdfViewerTopToolbar {
  isMobile: boolean;
  hideTooltip: Function;
  signingDrawerWithoutImageStateHandler: Function;
  signWithoutImageModalHandler: Function;
  signWithImageHandler: Function;
  signWithImage: Function;
  addSigImageInitial: Function;
  isSigAdded: boolean;
  deleteSigImage: Function;
  showRejectHandler: Function;
  errorStatePdf: boolean;
  loadingState: boolean;
}

function PdfViewerBottomToolbar(props: IPdfViewerTopToolbar) {
  const tooltipCtx = useContext(IntroTooltipContext);
  const signatureParamsCtx = useContext(SignatureParametersContext);

    if(signatureParamsCtx.backToDocAfterThirdPartySign){
        return null;
    }

    if (props.isMobile && !props.errorStatePdf && !props.loadingState) {
    return (
      <IntroTooltip
        tooltipTextIndex={1}
        open={
          tooltipCtx.showTooltips === true &&
          tooltipCtx.currentTooltipStep === 1
        }
        placement={"top"}
        hideTooltip={props.hideTooltip}
      >
        <div
          style={{
            alignItems: "center",
            backgroundColor: "white",
            border: "1px solid rgba(0, 0, 0, 0.2)",
            boxShadow: "black 0px 6px 12px 5px, black 0px 3px 7px -3px",
            bottom: "0px",
            display: "flex",
            left: "50%",
            height: "65px",
            width: "100%",
            padding: "4px",
            position: "absolute",
            transform: "translate(-50%, 0)",
            zIndex: 10000,
            marginBottom: "0px",
          }}
          className={styles.SignatorPdfToolbar}
        >
          <div className={styles.SignatorPdfToolbarLeft}>
            <div
              style={{
                marginRight: "5px",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <SignButtonMenu
                signingDrawerWithoutImageStateHandler={
                  props.signingDrawerWithoutImageStateHandler
                }
                signWithoutImageModalHandler={
                  props.signWithoutImageModalHandler
                }
                signWithImageHandler={props.signWithImageHandler}
                signWithImage={props.signWithImage}
                addSigImageInitial={props.addSigImageInitial}
                isSigAdded={props.isSigAdded}
                isMobile={props.isMobile}
              />
              {signatureParamsCtx.rejectUrl &&
                <SigImageButton
                  addSigImageInitial={props.addSigImageInitial}
                  signWithImageHandler={props.signWithImageHandler}
                  deleteSigImage={props.deleteSigImage}
                  isSigAdded={props.isSigAdded}
                  isMobile={props.isMobile}
                />
              }
            </div>
          </div>
          <div className={styles.SignatorPdfToolbarRight}>
            {signatureParamsCtx.rejectUrl && (
              <RejectButton
                showRejectHandler={props.showRejectHandler}
                isMobile={props.isMobile}
              />
            )}
            {!signatureParamsCtx.rejectUrl && (
              <SigImageButton
                addSigImageInitial={props.addSigImageInitial}
                signWithImageHandler={props.signWithImageHandler}
                deleteSigImage={props.deleteSigImage}
                isSigAdded={props.isSigAdded}
                isMobile={props.isMobile}
              />
            )}
          </div>
        </div>
      </IntroTooltip>
    );
  }

  if (!props.isMobile && !props.errorStatePdf && !props.loadingState) {
    return (
      <IntroTooltip
        tooltipTextIndex={1}
        open={
          tooltipCtx.showTooltips === true &&
          tooltipCtx.currentTooltipStep === 1
        }
        placement={"top"}
        hideTooltip={props.hideTooltip}
      >
        <div
          style={{
            alignItems: "center",
            backgroundColor: "white",
            border: "1px solid rgba(0, 0, 0, 0.2)",
            boxShadow:
              "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
            borderRadius: "35px",
            bottom: "5px",
            display: "flex",
            left: "50%",
            height: "65px",
            minWidth: "580px",
            padding: "4px",
            position: "absolute",
            transform: "translate(-50%, 0)",
            zIndex: 10000,
          }}
          className={
            tooltipCtx.showTooltips === true &&
            tooltipCtx.currentTooltipStep === 1
              ? styles.SignatorPdfToolbarAnimation
              : styles.SignatorPdfToolbar
          }
        >
          <div className={styles.SignatorPdfToolbarLeft}>
            <div
              style={{
                marginRight: "20px",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <SignButtonMenu
                signingDrawerWithoutImageStateHandler={
                  props.signingDrawerWithoutImageStateHandler
                }
                signWithoutImageModalHandler={
                  props.signWithoutImageModalHandler
                }
                signWithImageHandler={props.signWithImageHandler}
                signWithImage={props.signWithImage}
                addSigImageInitial={props.addSigImageInitial}
                isSigAdded={props.isSigAdded}
                isMobile={props.isMobile}
              />
                {signatureParamsCtx.rejectUrl &&
                    <SigImageButton
                        addSigImageInitial={props.addSigImageInitial}
                        signWithImageHandler={props.signWithImageHandler}
                        deleteSigImage={props.deleteSigImage}
                        isSigAdded={props.isSigAdded}
                        isMobile={props.isMobile}
                    />
                }
            </div>
          </div>
          <div className={styles.SignatorPdfToolbarRight}>
              {signatureParamsCtx.rejectUrl &&
                  <RejectButton
                      showRejectHandler={props.showRejectHandler}
                      isMobile={props.isMobile}
                  />
              }
              {!signatureParamsCtx.rejectUrl && (
                  <SigImageButton
                      addSigImageInitial={props.addSigImageInitial}
                      signWithImageHandler={props.signWithImageHandler}
                      deleteSigImage={props.deleteSigImage}
                      isSigAdded={props.isSigAdded}
                      isMobile={props.isMobile}
                  />
              )}
          </div>
        </div>
      </IntroTooltip>
    );
  }

  return null;
}

export default PdfViewerBottomToolbar;
